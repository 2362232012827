import { useContext } from "react";
import { CompanyContext } from "./contexts/companyCotext";
var host = window.location.host;
const hostloc = window.location.host;
var nameCompany = "";
if (hostloc.startsWith("localhost") || hostloc == "ishatri.com") {
  nameCompany = "Ishtari";
} else if (hostloc == "energy-plus.com" || hostloc == "energyplus-lb.com") {
  nameCompany = "Energy-Plus";
} else if (hostloc == "ishtari-usd.com") {
  nameCompany = "Ishtari-Usd";
} else if (hostloc == "flo-lebanon.com") {
  nameCompany = "Flo";
}
const _apis = {
  Ishtari: "https://www.ishtari-mobile.com/v2/index.php",
  Issa: "https://issa.ishtari.com/api/v2/index.php",
  "Ishtari-Energy": "https://energyplus-lb.com/api/v2/index.php",
  "Ishtari-Ghana": "https://www.ishtari.com.gh/v2/index.php",
  "Ishtari-Usd": "https://www.ishtari-usd.com/api/v2/",
  "Energy-Plus": "https://www.energyplus-lb.com/api/v2/",
  Flo: "https://www.flo-lebanon.com/api/v2/index.php",
  "Flo-Beirut": "https://www.flo-lebanon.com/bey/api/v2/",
  Aalbeit: "https://www.aalbeit.com/api/v2/",
  English_Home: "https://www.englishhome.com.lb/api/v2/index.php",
  "English-Home-1": "https://www.englishhome.com.lb/store_one/api/v2/",
  "English-Home-2": "https://www.englishhome.com.lb/store_two/api/v2/",
  // 'snipex': 'https://snipextc.com/api/v2/',
};
const requests = {
  login: "?route=stockapi/admin_login",
  logout: "?route=stockapi/admin_logout",
  changeUpcQuantity: "?route=stockapi/product/changeUpcQuantity",
  /// These 2 req are used to fetch the order details (one for the normal order and the other for the return order)
  orderInfo: "?route=stockapi/order",
  returnOrderInfo: "?route=stockapi/return_order",
  /// These 2 req are used to prepare the order (the order status will be Ready)
  productsFullVersion: "?route=stockapi/products_fullversion",
  returnProductsFullVersion: "?route=stockapi/return_products_fullversion",
  transferproductsFullVersion: "?route=stockapi/transfer_products_fullversion",
  returnTransferProductsFullVersion: "?route=stockapi/return_transfer_products_fullversion",
  /* These 2 req are used in Search Product Screen
   The first req is for fetching the product
  The second req is for updating the product qty */
  productInfo: "?route=stockapi/product",
  updateproductStatus: "?route=stockapi/product/updateStatus",
  updateQuantity: "?route=stockapi/product/updateQuantity",
  // These 3 req are used to change the upc and big upc of product(s)
  updateUPC: "?route=stockapi/product/updateUPC",
  updateBigUPC: "?route=stockapi/product/updateBigUPC",
  updateMainUPC: "?route=stockapi/product/updateMainUPC",
  updateUpcOption: "?route=stockapi/product/update_upc_option",
  // These 2 req are used to change the status of an order (Ready, Given, Failed,...)
  updateOrder: "?route=stockapi/update_order",
  updateTransfer: "?route=stockapi/update_transfer",
  getNbOfPackage: "?route=stockapi/update_order/getNbOfPackage",
  updateReturnOrder: "?route=stockapi/update_return_order",
  // This req is used for fetching all the logistics
  logistics: "?route=stockapi/logistic",
  jardeh: "?route=stockapi/jardeh",
  //RETURN REQ
  // arrived req:
  arrived: "?route=stockapi/return_products_fullversion/returnorderproducts",
  // send to control and control reqs
  returnOrderProduct:
    "?route=stockapi/return_products_fullversion/returnOrderProduct",
  // update to arrived , control , defect , maintenance , defect
  updateReturnOrderProduct:
    "?route=stockapi/update_return_order/updateReturnOrderProductsStatus",
  getDataEntryOrder: "?route=stockapi/product_to_data_entry",
  updateProductStatus:
    "?route=stockapi/product_to_data_entry/updateProductStatus",
  restockUPC: "?route=stockapi/product/restockUpcHistory",
  manageUPCQty: "?route=stockapi/product/changeMinMaxUpcQuantity",
  checkProductUPCQuantity: "?route=stockapi/product/checkProductUpcQuantity",
  getMinMaxUpcQuantity: "?route=stockapi/product/getMinMaxUpcQuantity",
  checkProductDelay: "?route=stockapi/update_order/checkProductDelay",
  checkReturnProductDelay:
    "?route=stockapi/update_return_order/checkReturnProductDelay",
  jardehBatchProducts: "?route=stockapi/jardeh/batchProducts",
  jardehProducts: "?route=stockapi/jardeh/jardehProducts",
  deleteJardehProducts: "?route=stockapi/jardeh/deleteJardehProducts",
  addJardeh: "?route=stockapi/jardeh/add",
  addJardehishtari: "?route=stockapi/jardeh/add_ishtari",
  getJardehBatch: "?route=stockapi/jardeh/jardehBatches",
  deleteJardeh: "?route=stockapi/jardeh/deletejardeh",
  deleteBatch: "?route=stockapi/jardeh/deleteBatch",
  pallet: "?route=stockapi/pallet",
  getToken: "?route=token/token&grant_type=client_credentials",
  getPalletProducts: "?route=stockapi/pallet/getProductsByPallet",
  assignToPallet: "?route=stockapi/pallet/assignProductsToPallet",
  unasignFromPallet: "?route=stockapi/pallet/unassignProductsFromPallet",
  assignFloor: "?route=stockapi/pallet/assignPalletFloor",
  unassignFloor: "?route=stockapi/pallet/unassignPalletFloor",
  getBinProducts: "?route=stockapi/warehouse/getBinProducts",
  getBinInfo: "?route=stockapi/warehouse/getProductInfo",
  getWarehouseProductInfo: "?route=stockapi/warehouse/getWarehouseProductInfo",
  getTransferOrderByProduct: "?route=stockapi/warehouse/getTransferOrderByProduct",
  assignBin: "?route=stockapi/warehouse/addAddressToProduct",
  unassignBin: "?route=stockapi/warehouse/removeAddressFromProduct",
  updateUpcORder: "?route=stockapi/update_order/updateUpcOrder",
  updateBinORder: "?route=stockapi/update_order/updateBinOrder",
  updateReturnBin: "?route=stockapi/update_order/updateReturnBinOrder",
  updateTransfer: "?route=stockapi/update_transfer",
  pickupOrder: "?route=stockapi/pickup_order", //awal ma yfout 3l pickup order btetnafaz // &user_id=
  getPickupOrders: "?route=stockapi/pickup_order/getPickupOrder",
  getPickupOrderProducts: "?route=stockapi/pickup_order/getPickupOrderProducts",
  getPickupReturnOrderProducts:
    "?route=stockapi/pickup_order/getPickupReturnOrderProducts",
  checkShelfLabel: "?route=stockapi/pickup_order/checkShelfLabel", //&order_id=&label=scannedvalue&type=key
  pickupOrderProduct: "?route=stockapi/pickup_order/pickupOrderProduct",
  missingPickupOrderProduct: "?route=stockapi/pickup_order/missingPickupOrderProduct",
  pickupReturnOrderProduct:
    "?route=stockapi/pickup_order/pickupReturnOrderProduct",
    missingPickupReturnOrderProduct:
    "?route=stockapi/pickup_order/missingPickupReturnOrderProduct", 
  assignProductToBin: "?route=stockapi/warehouse/assignProductToBin",
  ctn_labeling: "?route=stockapi/ctn_labeling/ctnScannCode",
  assignWarehouseProductToBin:
    "?route=stockapi/warehouse/assignWarehouseProductToBin",
  unassignProductFromBin: "?route=stockapi/warehouse/unassignProductFromBin",
  unassignWarehouseProductFromBin:
    "?route=stockapi/warehouse/unassignWarehouseProductFromBin",
  receivedProductQty: "?route=stockapi/product/receiveProductQuantity",
  getProductHistory: "?route=stockapi/product/getProductHistory", //&label=76626&limit=5&page=2
  restockBigUpc: "?route=stockapi/product/restockBigUpcHistory",
  graphPage: "?route=stockapi/user_profile",
  getReceivedProducts: "?route=stockapi/warehouse/getReceivedProducts",
  getWarehouseProductDetails:
    "?route=stockapi/warehouse/getWarehouseProductDetails",
  updateReference: "?route=stockapi/warehouse/updateReference",
  checkProduct: "?route=stockapi/warehouse/checkProductSerialNumber",
  getTransfer: "?route=stockapi/update_transfer/getTransfer", //&transfer_id=181 //for flo and english home
  pickTransferProducts: "?route=stockapi/update_transfer/pickTransferProducts",
  returnReasonforProduct:
    "?route=stockapi/update_return_order/getReturnOrderProductInfo",
  refillProduct: "?route=stockapi/product/refillProduct", //param return_order_product_id
  updateBin: "?route=stockapi/warehouse/changeProductBin", //this request used to change the product bin
  getproductsinwarehouse:
    "?route=stockapi/warehouse/getWarehouseQuantyByProduct",
  fetchtowarehouse: "?route=stockapi/warehouse/getAllWarehouses",
  fromwarehouse: "?route=stockapi/warehouse/getAllowedWarehousesByWarehouse",
  fetchfromwarehouse: "?route=stockapi/warehouse/getAllWarehouses",
  fetchReceivedWarehouseProducts: "?route=stockapi/warehouse/getAllWarehouses",
  towarehouse: "?route=stockapi/warehouse/getAllowedWarehousesByWarehouse",
  submitwarehousechange: "?route=stockapi/warehouse/transferWarehouseProducts",
  changeWarehouseProductBin:
    "?route=stockapi/warehouse/changeWarehouseProductBin",
  getTransferOrderById: "?route=stockapi/warehouse/getTransferOrderById",
  getTransferOrderProducts:
    "?route=stockapi/warehouse/getTransferOrderProducts",
  changeProductStatus: "?route=stockapi/warehouse/changeProductStatus",
};

let storeParams = "";
function buildLink(link, payload, width) {
  var apiUrl = "";
  if (
    host.startsWith("localhost") || 
    host === "pda.ishtari.com" ||
    host === "next.ishtari.com.gh"
  ) {
    apiUrl = _apis["Ishtari"];
  } else if (host == "pda.ishtari.com.gh") {
    apiUrl = _apis["Ishtari-Ghana"];
  }
  else if (host === "pda.energyplus-lb.com") {
    apiUrl = _apis["Ishtari-Energy"];
  }
  else if ( host == "issa-pda.ishtari.com") {
    apiUrl = _apis["Issa"];
  }
  
   else if (host === "pda.flo-lebanon.com") {
    apiUrl = _apis["Flo"];
    const store = localStorage.getItem("store_id");

    storeParams = store == null ? "" : `&store_id=${store}`;
    // alert(store)
  } else if (   host === "pda.englishhome.com.lb") {
    apiUrl = _apis["English_Home"];
    const store = localStorage.getItem("store_id");

    storeParams = store ? `&store_id=${store}` : "";
    // alert(store)
  }

  const extra_params = typeof payload == "undefined" ? "" : payload;
  return `${apiUrl}` + requests[link] + extra_params + storeParams;
}
export default buildLink;
