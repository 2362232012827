import { useContext, useEffect, useState } from "react";
import axios from "axios";
import buildLink from "../urls";
import { CompanyContext } from "../contexts/companyCotext";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { PlaySounds } from "./playSounds";
import MessageAlert from "../components/messageAlert";

export const OroderStatusHook = () => {
  const [loading, setLoading] = useState(false);
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [logisticSelect, setLogisticSelect] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("S");
  const [showMessageErr, setShowMessageErr] = useState(false);
  const [constNumPack, setConstNumPack] = useState(0);
  const [orderList, setOrdersList] = useState([]);
  const [orderStatus, setorderStatus] = useState("normal");
  const [numPackage, setNumPackage] = useState(1);
  const [OldOrderId, setOldOrderId] = useState(null);

  let orderListCookies =
    Cookies.get("ordersHistory") === undefined
      ? []
      : JSON.parse(Cookies.get("ordersHistory"));
  var orderListVar = orderListCookies;

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

 

  const updateOrderStatus = async (e = "", type = "", orderId = "") => {
    const userID = getUserIdFromLocalStorage();
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yy HH:mm:ss");
    e.preventDefault();

    if (numPackage > 1) {
      if (OldOrderId === orderId || !OldOrderId) {
        setNumPackage(numPackage - 1);
        setOldOrderId(orderId);
      }
    } else if (numPackage === 1 && (OldOrderId === orderId || !OldOrderId)) {
      setLoading(true);
      const obj = {
        logistic_id: logisticSelect,
        user_id: userID,
        type: type,
        nb_of_pack: constNumPack,
        upc_id: "",
        item: "",
      };
      var url = "";
      if (orderStatus === "normal") {
        obj.order_id = orderId;
        url = buildLink("updateOrder");
      } else {
        obj.return_order_id = orderId;
        url = buildLink("updateReturnOrder");
      }

      try {
        const response = await axios.put(url, obj);

        const objtable = {
          date: formattedDate,
          user: stateCom.username,
          id: orderId,
          status: response.data.data.current_status,
          reason: response.data.data.message,
          success: true,
        };
        setOrdersList((prevProducts) => [objtable, ...prevProducts]);

        orderListVar.push(objtable);
        handleSetCookies(orderListVar);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        setLoading(false);
        playSuccessSound();
        setMessage(response.data.message);
        return true;
      } catch (e) {
        const objtable = {
          date: formattedDate,
          user: stateCom.username,
          id: orderId,
          status: e.response.data.data.current_status,
          reason: e.response.data.message,
          success: false,
        };
      
        // Update orders list state immutably
        setOrdersList((prevProducts) => [...prevProducts, objtable]);
        setMessage(e.response.data.message);
        // Ensure we don't mutate the state variable directly
        const updatedOrderListVar = [...orderListVar, objtable];
        handleSetCookies(updatedOrderListVar);
      
        setLoading(false);
    

        setMessage( e.response.data.message);
        setShowMessageErr(true);
        playErrorSound();
        
        setTimeout(() => {
          setShowMessageErr(false);
        }, 10000);
       
      
        return false;
      }
      
      setLoading(false);
      setOldOrderId(null);
    }
  };

  const handleRadioChange = (event) => {
    setNumPackage(Number(event.target.value));
    setConstNumPack(Number(event.target.value));
  };

  const handleSetCookies = (list) => {
    const listString = JSON.stringify(list);
    Cookies.set("ordersHistory", listString);
  };

  return {
    updateOrderStatus,
    showMessageErr,
    setShowMessageErr,
    setorderStatus,
    numPackage,
    setNumPackage,
    setLogisticSelect,
    setOrdersList,
    orderList,
    message,
    showMessgSucc,
    loading,
    handleRadioChange,
    handleSetCookies
  };
};
