export const CompanyReducer = (state, action) => {
  switch (action.type) {
    case "setCompany":
      return {
        ...state,
        company: action.payload,
      };
    case "setUsername":
      return {
        ...state,
        username: action.payload,
      };
      case "setWarehouseID":
        return {
          ...state,
          warehouse_id: action.payload,
        };
        case "setWarehouseName":
          return {
            ...state,
            warehouse_name: action.payload,
          };
      case "setPermission":
        return {
          ...state,
          PermissionList: action.payload,
        };
    case "setLoged":
      return {
        ...state,
        loged: action.payload,
      };
    case "setUserId":
      return {
        ...state,
        userID: action.payload,
      };
      case "setVerison":
        return {
          ...state,
          version: action.payload
        }
        case "setAvailableUpdate":
          return {
            ...state,
            availableUpdate: action.payload
          }
          case "setStoreName":
          return {
            ...state,
            StoreName: action.payload
          }
          case "setStoreId":
          return {
            ...state,
            StoreId: action.payload
          }
          case "setStoreList":
      return {
        ...state,
        storeList: action.payload,
      };

    default:
      return state;
  }
};

export const initialState = {
  userID: "",
  company: "",
  username: "",
  warehouse_id: "",
  warehouse_name:"",
  loged: false,
  availableUpdate:false,
  version : "",
  storeList: "",
  StoreId: "",
  PermissionList: [],
};
