import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../../contexts/companyCotext";
import { LogisticContext } from "../../../../contexts/logisticContext";
import Loader from "../../../../components/loader";
import { IoIosWarning } from "react-icons/io";
import MessageAlert from "../../../../components/messageAlert";
import { OroderStatusHook } from "../../../../functions/updateOrodersStatus";
import hocStarredPage from "../../../../components/hocStarredPage";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { PlaySounds } from "../../../../functions/playSounds";

const Failedv2 = () => {
  const orderId = useRef(null);
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];
  const [stateLogistic, dispatchStateLogistic] = useContext(LogisticContext);
  const [loading, setLoading] = useState(false);
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [logisticSelect, setLogisticSelect] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("S");
  const [showMessageErr, setShowMessageErr] = useState(false);
  const [constNumPack, setConstNumPack] = useState(0);
  const [orderList, setOrdersList] = useState([]);
  const [orderStatus, setorderStatus] = useState("normal");
  const [numPackage, setNumPackage] = useState(1);
  const [OldOrderId, setOldOrderId] = useState(null);

  let orderListCookies =
    Cookies.get("ordersHistory") === undefined
      ? []
      : JSON.parse(Cookies.get("ordersHistory"));
  var orderListVar = orderListCookies;

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

 

  const updateOrderStatus = async (e = "", type = "", orderId = "") => {
    const userID = getUserIdFromLocalStorage();
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yy HH:mm:ss");
    e.preventDefault();

    if (numPackage > 1) {
      if (OldOrderId === orderId || !OldOrderId) {
        setNumPackage(numPackage - 1);
        setOldOrderId(orderId);
      }
    } else if (numPackage === 1 && (OldOrderId === orderId || !OldOrderId)) {
      setLoading(true);
      const obj = {
        logistic_id: logisticSelect,
        user_id: userID,
        type: type,
        nb_of_pack: constNumPack,
        upc_id: "",
        item: "",
      };
      var url = "";
      if (orderStatus === "normal") {
        obj.order_id = orderId;
        url = buildLink("updateOrder");
      } else {
        obj.return_order_id = orderId;
        url = buildLink("updateReturnOrder");
      }

      try {
        const response = await axios.put(url, obj);

        const objtable = {
          date: formattedDate,
          user: stateCom.username,
          id: orderId,
          status: response.data.data.current_status,
          reason: response.data.data.message,
          success: true,
        };
        setOrdersList((prevProducts) => [objtable, ...prevProducts]);

        orderListVar.push(objtable);
        handleSetCookies(orderListVar);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        setLoading(false);
        playSuccessSound();
        setMessage(response.data.message);
        return true;
      } catch (e) {
        const objtable = {
          date: formattedDate,
          user: stateCom.username,
          id: orderId,
          status: e.response.data.data.current_status,
          reason: e.response.data.message,
          success: false,
        };
      
        // Update orders list state immutably
        setOrdersList((prevProducts) => [...prevProducts, objtable]);
        setMessage(e.response.data.message);
        // Ensure we don't mutate the state variable directly
        const updatedOrderListVar = [...orderListVar, objtable];
        handleSetCookies(updatedOrderListVar);
      
        setLoading(false);
    

        setMessage( e.response.data.message);
        setShowMessageErr(true);
        playErrorSound();
        
        setTimeout(() => {
          setShowMessageErr(false);
        }, 10000);
       
      
        return false;
      }
      
      setLoading(false);
      setOldOrderId(null);
    }
  };


  const {
    setOrderList,
    handleRadioChange,
    handleSetCookies
  } = OroderStatusHook();

  const history = useNavigate();

  useEffect(() => {
    orderId.current.focus();
  }, []);

  const handleTrashClick = () => {
     orderId.current.focus();
     orderId.current.value = "";
  };
  var host = window.location.host;
  

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Failed</h2>
            </div>
          </div>
        </div>
        <div className=" container">
          <div className=" py-3">
            <div>Order Type</div>
            <Select
              placeholder="Order Type"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              onChange={(e) => {
                setorderStatus(e.value);
              }}
              options={options}
            />
          </div>
          <div className=" py-3">
            <div>Logistics</div>
            <Select
              placeholder="Logistics"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              options={stateLogistic.logisticList}
              onChange={(e) => {
                setLogisticSelect(e.value);
              }}
            />
            <div className="p-1 mt-3">Number of Packages:</div>
            <div className="space-x-3 flex flex-wrap">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((value) => (
                <label
                  htmlFor={`pack${value}`}
                  className="flex gap-2 flex-row"
                  key={value}
                >
                  <input
                    type="radio"
                    id={`pack${value}`}
                    name="number"
                    value={value}
                    // checked={selectedValue === `${value}`}
                    onChange={handleRadioChange}
                  />
                  <div>{value}</div>
                </label>
              ))}
            </div>
          </div>
          <div className="-mt-2 -mb-2">Order ID</div>

           <form
      onSubmit={(e) => {
      e.preventDefault();
      if (  host === "pda.ishtari.com.gh"  ) {
      updateOrderStatus(e, "prepare_awaiting_failed", orderId.current.value);
      handleTrashClick();
       } else {
         updateOrderStatus(e, "awaiting_failed", orderId.current.value);
         handleTrashClick();
      }
     }}
    className="gap-2 flex py-3"
    >

            <div className="relative flex-1">
              <input
                ref={orderId}
                type="text"
                className="border-2 w-full rounded-md border-dbase py-2 pr-8 pl-2"
              />
              <FaTrash
                className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                onClick={() => handleTrashClick()}
              />
            </div>
            <button
              type="submit"
              className="w-fit cursor-pointer px-5 text-white text-center rounded-md bg-dbase"
            >
              <div className="flex  whitespace-nowrap  items-center justify-center h-full">
                {numPackage > 1 || numPackage === 0 ? (
                  numPackage
                ) : loading ? (
                  <Loader />
                ) : (
                  "Mark as Failed"
                )}
              </div>
            </button>
          </form>
        </div>
        <div className="container w-full rounded-md text-white flex justify-between ">
          <div className="border w-full flex-1  px-8  text-dgreyBlack border-dblack  py-2 flex items-center justify-center">
            {orderList.length}
          </div>
          <div
            onClick={() => setOrderList([])}
            className="border cursor-pointer w-full bg-dbase   flex-2  border-dblack  py-2 flex items-center justify-center"
          >
            Reset
          </div>
        </div>
        <div class="w-full mt-3 bg-white p-3 rounded">
          <table class="w-full border-dlabelColor border">
            <thead>
              <tr className=" border-b border-dlabelColor">
                <th class="py-2  border-dlabelColor border-r">Order ID</th>
                <th class="py-2 border-r">Status</th>
                <th class="py-2 border-dlabelColor">Reason</th>
              </tr>
            </thead>
            <tbody>
              {orderList &&
                orderList.map((item) => {
                  return (
                    <tr
                      className={` ${
                        item.success ? "text-dgreen" : "text-dbase"
                      } text-center border-b border-dlabelColor`}
                    >
                      <td class="py-2  border-dlabelColor border-r">
                        {item.id}
                      </td>
                      <td class="py-2  border-dlabelColor border-r">
                        {item.status}
                      </td>
                      <td class="py-2  border-dlabelColor border-r">
                        {item.message}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <MessageAlert
          message={message}
          type={showMessageErr ? "err" : "succ"}
          showMessg={showMessageErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(Failedv2);
