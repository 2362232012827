import React ,{useState,useRef,useContext} from "react";
import { PlaySounds } from "../../functions/playSounds";
import { useNavigate } from "react-router-dom";
import buildLink from "../../urls";
import axios from "axios";
import {
    FaArrowLeft,
    FaSearch,
    FaTrash,
  } from "react-icons/fa";
  import Loader from "../../components/loader";
  import MessageAlert from "../../components/messageAlert";
import { CompanyContext } from "../../contexts/companyCotext";

const BoxLabeling = () => {
    const {playErrorSound,playSuccessSound} = PlaySounds();
    const boxInput = useRef(null);
    const [showMessgSucc, setShowMessageSucc] = useState(false);
    const [message, setMessage] = useState("");
    const [showMessgErr, setShowMessageErr] = useState(false);
    const [loadingSearch,setLoading]=useState(false);
    const [stateCom] = useContext(CompanyContext);
  const [data,setData]=useState("");
    const history = useNavigate();

    const BoxLabelingPost = async (e) => {
        e.preventDefault();
        setLoading(true);
        const code = boxInput.current.value;
        try {
          const url = `${buildLink("ctn_labeling")}`;
          const response = await axios.post(url, {
            code,
            user_id: stateCom.userID,
          });
    
        
          if (response.data.success === true) {
            setMessage(response.data.message);
            setData(response.data.code);
            playSuccessSound();
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
              setMessage("");
            }, 3000);
          }
          else{
            setMessage(response.data.message);
            playErrorSound();
            setShowMessageErr(true);
            setTimeout(() => {
              setShowMessageErr(false);
              setMessage("");
            }, 3000);
          }
        } catch (error) {
          console.error("Error:", error);
          playErrorSound();
          if (error.response && error.response.data && error.response.data.message) {
            setMessage(error.response.data.message);
          }
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
            setMessage("");
          }, 3000);
        } finally {
          setLoading(false);
          boxInput.current.value = "";
        }
      };

      const handleTrashClick = () => {
        boxInput.current.value = "";
        boxInput.current.focus();
      };
    
  return (
    <>
     
       <div className=" h-full bg-white ">
       <div className="mb-5 sticky top-0 flex flex-col bg-white z-50">
        <div className="w-full bg-dbase h-16">
          <div className="container px-3 relative flex justify-between w-full text-white h-full">
            <div className="flex gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Box Labeling</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={BoxLabelingPost}>
          <div className="flex flex-col">
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
              <div className=" relative flex flex-row border-dblack rounded-lg text-center w-full gap-3">
                <input
                  ref={boxInput}
                  type="text"
                  className="border border-dblack rounded-lg block p-2 flex-grow text-start w-full h-12 pr-10" // Add pr-10 to create space for the icon
                  placeholder="Box Code"
                />
                <FaTrash
                  onClick={() => handleTrashClick()}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                />
              </div>

              <button className={`border border-dblack bg-dbase rounded-lg text-white px-5 h-12 ${
              loadingSearch ? "opacity-50 cursor-not-allowed" : ""
            } `}>
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </div>
        </form>
        {data&&(
          <h1 className="mt-3">Code is {data}</h1>
        )}
      </div>
        </div>
<MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
    </>
  );
};

export default BoxLabeling;


