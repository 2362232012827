import React, { useRef, useState, useContext, useEffect } from "react";
import MessageAlert from "../../../components/messageAlert";
import Loader from "../../../components/loader";
import axios from "axios";
import buildLink from "../../../urls";
import { PlaySounds } from "../../../functions/playSounds";
import { CompanyContext } from "../../../contexts/companyCotext";
import useDeviceSize from "../../../components/useDeviceSize";
import {
  FaArrowAltCircleDown,
  FaArrowLeft,
  FaEye,
  FaSearch,
  FaTrash,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { GoQuestion } from "react-icons/go";
import "swiper/css";
import "swiper/css/pagination";
import PointsLoader from "../../../components/PointsLoader";

const WarehouseProduct = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();

  const history = useNavigate();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
  const [error, setError] = useState(null);
  const productId = useRef(null);
  const [systemQuantity, setSystemQuantity] = useState(null);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keyboardType, setKeyboardType] = useState("text");
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const menuRefButton = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedSku, setSelectedSku] = useState("");
  const [width] = useDeviceSize();

  const getProducts = async (e) => {
    setProductInf(null);
    e.preventDefault();
    setLoadingSearch(true);
    setLoading(true);
    setError(null);
    try {
      const url =
        buildLink(`getproductsinwarehouse`) +
        `&product_id=${productId.current.value}`;
      const response = await axios.get(url);
      if (response.data.success) {
        setWahrehouesInfo(response.data.warehouses);
        setSystemQuantity(response.data.system_quantity);
        setMessage(response.data.message);
        setShowMessageSucc(true);
        playSuccessSound();
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
      } else {
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        playErrorSound();
        setWahrehouesInfo([]);
      }
    } catch (e) {
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      playErrorSound();
    } finally {
      setLoadingSearch(false);
      setLoading(false);
    }
  };

  const searchProduct = async (e) => {
    setProductInf(null);
    e.preventDefault();
    setLoading(true);
    if (productId.current) {
      productId.current.blur();
    }
    const item = productId.current.value;
    try {
      const url =
        buildLink("productInfo") +
        `&item=${item}&v2=true` +
        `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        setKeyboardType("none");
        if (response.data.success) {
          setProductInf(response.data.data);
        } else {
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      setLoading(false);
    }
  };

  function sanitizeHTML(html) {
    if (typeof window !== "undefined") {
      return DOMPurify.sanitize(html);
    } else {
      return html;
    }
  }

  const handleTrashClick = () => {
    productId.current.value = "";
    productId.current.focus();
  };

  function unescapeHTML(str) {
    if (!str) {
      return;
    }
    return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
      var match;

      if (entityCode in htmlEntities) {
        return htmlEntities[entityCode];
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
        return String.fromCharCode(parseInt(match[1], 16));
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#(\d+)$/))) {
        return String.fromCharCode(~~match[1]);
      } else {
        return entity;
      }
    });
  }
  var htmlEntities = {
    nbsp: " ",
    cent: "¢",
    pound: "£",
    yen: "¥",
    euro: "€",
    copy: "©",
    reg: "®",
    lt: "<",
    gt: ">",
    quot: '"',
    amp: "&",
    apos: "'",
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    productId.current.focus();
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function scrollToExistingHeader() {
    const orderHistoryElement = document.getElementById("order-history");
    const dataEntryHistoryElement =
      document.getElementById("data-entry-history");
    const returnOrderHistoryElement = document.getElementById(
      "return-order-history"
    );

    if (orderHistoryElement) {
      orderHistoryElement.scrollIntoView({ behavior: "smooth" });
    } else if (dataEntryHistoryElement) {
      dataEntryHistoryElement.scrollIntoView({ behavior: "smooth" });
    } else if (returnOrderHistoryElement) {
      returnOrderHistoryElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleButtonClick = () => {
    setShowTooltip(!showTooltip);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const handleOpenPopup = (orderId) => {
    setPopupData(orderId);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setPopupData(null);
  };
  var host = window.location.host;

  const onSubmit = (event) => {
    getProducts(event);
    if (host.startsWith("localhost") || host == "pda.ishtari.com") {
      searchProduct(event);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="mb-5 sticky top-0 flex flex-col bg-white z-50">
        <div className="w-full bg-dbase h-16">
          <div className="container px-3 relative flex justify-between w-full text-white h-full">
            <div className="flex gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Products</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className="flex flex-col">
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
              <div className=" relative flex flex-row border-dblack rounded-lg text-center w-full gap-3">
                <input
                  ref={productId}
                  type="text"
                  className="border border-dblack rounded-lg block p-2 flex-grow text-start w-full h-12 pr-10" // Add pr-10 to create space for the icon
                  placeholder="Product ID or SKU"
                />
                <FaTrash
                  onClick={() => handleTrashClick()}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                />
              </div>

              <button className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12">
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </div>
        </form>
      </div>

      {
        ((host === "pda.ishtari.com.gh" ) && (
          <>
            {systemQuantity && (
              <div className="text-center text-xl my-5">
                System Quantity: {systemQuantity}
              </div>
            )}
            {WahrehouesInfo.length > 0 && (
              <div className="overflow-x-auto mt-5 px-4 md:px-12 lg:px-32">
                <table className="min-w-full bg-white border border-dgreySeller">
                  <thead>
                    <tr className="bg-dgrey text-dblackk">
                      <th className="border px-4 py-2">Warehouse Name</th>
                      <th className="border px-4 py-2">Quantity</th>
                      <th className="border px-4 py-2">Address</th>
                      <th className="border px-4 py-2">Reference</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    {WahrehouesInfo.map((warehouse, index) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="border px-4 py-2 text-center">
                          {warehouse.warehouse_name}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.quantity}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.address_id}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.reference}
                        </td>
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ))}

      {loading ? (
        <PointsLoader />
      ) : (
        <div className="container ">
          {productInf && (
            <div className=" h-full flex flex-col justify-center text-center gap-3">
               {productInf.status &&(
              <div className={`mt-3 text-lg flex`}>
                      <span>Status:</span>{" "}
                      <span
                        className={` ml-1 ${
                          productInf.status === "0"
                            ? "text-dhotPink"
                            : "text-dgreen"
                        }`}
                      >
                        {productInf.status === "0" ? "Disabled" : "Enabled"}
                      </span>
                    </div>
               )}
              <div className="flex px-2 py-2 flex-row my-2 container justify-between w-full">
                <div
                  className={` overflow-hidden h-54 w-48 my-auto ${
                    width > 650 ? "w-auto h-auto px-40 py-40 -mt-32" : ""
                  }`}
                  style={{ width: "50%" }}
                >
                  {productInf.images && productInf.images.length > 0 ? (
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {productInf.images.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            className="relative cursor-pointer border border-slate200 px-1 py-1 w-full h-full"
                            src={image}
                            alt={`Image ${index}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <img
                      className="object-cover w-full h-full"
                      src={productInf.image}
                    />
                  )}
                </div>

                {width > 650 ? (
                  <div className="flex flex-row space-x-40 items-center w-full h-full mt-20">
                    <div
                      className=" bg-white space-y-10"
                      style={{ width: "100%" }}
                    >
                      <h2 className="font-semibold  items-center bg-dbase text-white">
                        Type:
                      </h2>
                      <h2 className="flex justify-center container py-1.5 border w-40 h-40 items-center">
                        {productInf.entity_type}
                      </h2>
                    </div>
                    <div
                      className=" bg-white space-y-10"
                      style={{ width: "100%" }}
                    >
                      <h2 className="font-semibold  items-center bg-dbase text-white">
                        Search:
                      </h2>
                      <h2 className="flex justify-center container py-1.5 border w-40 h-40 items-center">
                        {productInf.matched_column}
                      </h2>
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center"
                    style={{ width: "50%" }}
                  >
                    <div
                      className="container bg-white"
                      style={{ width: "100%" }}
                    >
                      <h2 className="font-semibold mr-36">Type:</h2>
                      <h2 className="flex justify-center h-10 w-full py-1.5 border">
                        {productInf.entity_type}
                      </h2>
                    </div>
                    <div
                      className="container bg-white"
                      style={{ width: "100%" }}
                    >
                      <h2 className="font-semibold mr-36">Search:</h2>
                      <h2 className="flex justify-center h-10 w-full py-1.5 border">
                        {productInf.matched_column}
                      </h2>
                    </div>
                  </div>
                )}
              </div>
              {productInf.product_description != "none" && (
                <div>
                  <h2 className="text-lg font-bold mb-1  text-dbase1 text-left">
                    Description
                  </h2>

                  <div
                    className="  p-2 border text-left max-h-56 overflow-y-auto  relative"
                    dangerouslySetInnerHTML={{
                      __html: unescapeHTML(
                        sanitizeHTML(productInf?.product_description)
                      ),
                    }}
                  ></div>
                </div>
              )}
              <div>
                <h2 className="text-lg font-bold mb-1 text-dbase1 text-left">
                  Product
                </h2>
                <div className="overflow-x-auto max-w-full">
                  <table className="w-full border-collapse border">
                    <thead>
                      <tr className="bg-gray-100 border-b">
                        <th className="p-4 border-r">ID</th>
                        <th className="p-4 border-r">SKU</th>
                        <th className="p-4 border-r">Price</th>
                        <th className="p-4 border-r">Barcode</th>
                        {/* <th className="p-4 border-r">Big Upc</th> */}
                        <th className="p-4 border-r">QTY</th>
                        <th className="p-4 border-r">System Quantity</th>
                        <th className="p-4 border-r">Fake Qty</th>
                        <th className="p-4 border-r">Rec Qty</th>
                        {productInf.entity_type === "Option" && (
                          <th className="p-4">{productInf.option_name}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b">
                        <td className="p-4 border-r">
                          {productInf.entity_id === ""
                            ? "_"
                            : productInf.entity_id}
                        </td>
                        <td className="p-4 border-r">
                          {productInf.sku === "" ? "_" : productInf.sku}
                        </td>
                        <td className="p-4 border-r">
                          {productInf.special === "" ? "_" : productInf.special}
                        </td>
                        <td className="p-4 border-r">
                          {productInf.barcode === "" ? "_" : productInf.barcode}
                        </td>
                        {/* <td className="p-4 border-r">
                          {productInf.big_upc === "" ? "_" : productInf.big_upc}
                        </td> */}
                        <td className="p-4 border-r">
                          {productInf.entity_type === "Option"
                            ? productInf.option_quantity
                            : productInf.quantity === ""
                            ? "_"
                            : productInf.quantity}
                        </td>
                        <td className="p-4 border-r"> {systemQuantity}</td>
                        <td className="p-4 border-r">
                          {productInf.fake_quantity === ""
                            ? "_"
                            : productInf.fake_quantity}
                        </td>
                        <td className="p-4 border-r">
                          {productInf.received_quantity === ""
                            ? "_"
                            : productInf.received_quantity}
                        </td>
                        {productInf.entity_type === "Option" && (
                          <td className="p-4">
                            {productInf.option_value_name}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {WahrehouesInfo.length > 0 && (
                <div>
                  <h2 className="text-lg font-bold mb-1 text-dbase1 text-left">
                    Product In Warehouses
                  </h2>
                  <div className="overflow-x-auto max-w-full">
                  <table className="w-full border-collapse border overflow-scroll">
                    <thead>
                      <tr className="bg-dgrey text-dblackk">
                        <th className="border px-4 py-2">Warehouse Name</th>
                        <th className="border px-4 py-2">Quantity</th>
                        <th className="border px-4 py-2">Address</th>
                        <th className="border px-4 py-2">Reference</th>
                        <th className="border px-4 py-2">Pending Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {WahrehouesInfo.map((warehouse, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="border px-4 py-2 text-center">
                            {warehouse.warehouse_name}
                          </td>
                          <td className="border px-4 py-2 text-center">
                            {warehouse.quantity}
                          </td>
                          <td className="border px-4 py-2 text-center">
                            {warehouse.address_id}
                          </td>
                          <td className="border px-4 py-2 text-center">
                            {warehouse.reference}
                          </td>
                          <td className="border px-4 py-2 text-center">
                          {warehouse.pending_qty}
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                </div>
              )}
              <div className="flex relative items-center">
                <div className="w-3/5 mt-6 border-t border-b border-l bg-dplaceHolder py-2">
                  <div className="w-full text-left ml-2">
                    In Stand Qty :{" "}
                    {productInf.in_stand_quantity &&
                      productInf.in_stand_quantity}
                  </div>
                </div>
                <div className="w-1/5 mt-6 border-t border-b bg-dplaceHolder py-3 flex items-center justify-center">
                  <button onClick={scrollToExistingHeader} className="">
                    <FaArrowAltCircleDown />
                  </button>
                </div>

                <div className="w-1/5 mt-6 border bg-dplaceHolder py-3 flex items-center justify-center">
                  <button
                    data-ripple-light="true"
                    data-tooltip-target="tooltip"
                    onClick={handleButtonClick}
                  >
                    <GoQuestion />
                  </button>
                </div>

                {showTooltip && (
                  <div
                    data-tooltip="tooltip"
                    className="absolute   md:-top-8 -top-16 z-20 right-0 whitespace-normal break-words bg-black py-1.5 px-3 text-white bg-dbase1"
                  >
                    {productInf.in_stand_equation}
                  </div>
                )}
              </div>

           


              {productInf.all_options.length > 0 && (
                <div>
                  <h2 className="text-lg font-bold text-dbase1 text-left mb-1 mt-4">
                    Other Options:
                  </h2>
                  <table className="table-auto w-full rounded-md border">
                    <tbody>
                      <tr>
                        <th className="border text-center  px-2 py-2 whitespace-pre-wrap">
                          ID
                        </th>
                        <th className="border text-center  px-2 py-2 whitespace-pre-wrap">
                          {productInf.all_options[0].name}
                        </th>
                        <th className="border text-center  px-2 py-2 whitespace-pre-wrap">
                          QTY
                        </th>
                        <th className="border text-center  px-2 py-2 whitespace-pre-wrap">
                          STAND QTY
                        </th>
                        <th className="border text-center  px-2 py-2 whitespace-pre-wrap">
                          STAND
                        </th>
                      </tr>
                      {productInf.all_options[0].product_option_values.map(
                        (option) => (
                          <>
                            <tr>
                              <td
                                className={`border text-center  px-2 py-2 whitespace-pre-wrap`}
                              >
                                {option.product_option_value_id}
                              </td>
                              <td
                                className={`  border text-center  px-2 py-2 whitespace-pre-wrap`}
                              >
                                {option.name === "" ? "_" : option.name}
                              </td>
                              <td
                                className={`border ${
                                  option.quantity < 5
                                    ? "text-dyellow"
                                    : "text-dgreen"
                                } text-center border-dblack px-2 py-2 whitespace-pre-wrap`}
                              >
                                {option.quantity === "" ? "_" : option.quantity}
                              </td>
                              <td
                                className={`border ${
                                  option.in_stand_quantity < 5
                                    ? "text-dyellow"
                                    : "text-dgreen"
                                } text-center border-dblack px-2 py-2 whitespace-pre-wrap`}
                              >
                                {option.in_stand_quantity === ""
                                  ? "_"
                                  : option.in_stand_quantity}
                              </td>
                              <td
                                className={`border text-center  px-2 py-2 whitespace-pre-wrap`}
                              >
                                {option.stand === "" ? "_" : option.stand}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              <div>
                <h2
                  id="order-history"
                  className="text-lg font-bold text-dbase1 text-left mb-1 mt-4"
                >
                  Order History:
                </h2>
                <div className="overflow-x-auto max-w-full">
                  <table className="w-full border-collapse border">
                    <thead>
                      <tr className="bg-gray-100 border-b">
                        <th className="p-4 border-r">Date</th>
                        <th className="p-4 border-r">Status</th>
                        <th className="p-4">Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productInf.statusGroupQty.order.map((order, index) => (
                        <tr key={index} className="border-b">
                          <td className="p-4 border-r">
                            {order.date_added || "_"}
                          </td>
                          <td className="p-4 border-r">
                            {order.status_group_name || "_"}
                          </td>
                          <td className="p-4">
                            {order.quantity || "_"}
                            <button
                              className="ml-2 text-blue-500"
                              onClick={() => handleOpenPopup(order.order_id)}
                            >
                              <FaEye />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Popup */}
              </div>

           

              <div className="mb-10">
                <h2
                  id="return-order-history"
                  className="text-lg font-bold text-dbase1 text-left mb-1 mt-4"
                >
                  Return Order History:
                </h2>
                <div className="overflow-x-auto max-w-full">
                  <table className="w-full border-collapse border">
                    <thead>
                      <tr className="bg-gray-100 border-b">
                        <th className="p-2 border-r">Date</th>
                        <th className="p-2 border-r">Status</th>
                        <th className="p-2">Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productInf.statusGroupQty.return &&
                      productInf.statusGroupQty.return.length > 0 ? (
                        productInf.statusGroupQty.return.map((item, index) => (
                          <tr key={index} className="border-b">
                            <td className="p-2 border-r">
                              {item.date_added || "_"}
                            </td>
                            <td className="p-2 border-r">
                              {item.status_group_name || "_"}
                            </td>
                            <td className="p-2">
                              {item.quantity || "_"}
                              <button
                                className="ml-2 text-blue-500"
                                onClick={() => handleOpenPopup(item.order_id)}
                              >
                                <FaEye />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="border-b">
                          <td className="p-2 border-r">_</td>
                          <td className="p-2 border-r">_</td>
                          <td className="p-2">_</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* Popup */}
                {isPopupOpen && (
                  <div
                    className="fixed inset-0 flex items-center justify-center bg-dblack bg-opacity-50"
                    onClick={handleClosePopup}
                  >
                    <div
                      className="bg-white p-6 rounded shadow-lg"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <h3 className="text-lg font-bold mb-4">Order Details</h3>
                      <div className="mb-4">
                        <strong>Order IDs:</strong>
                        <div className="mt-2">
                          {popupData
                            ? popupData.split(",").map((id, idx) => (
                                <p key={idx} className="text-sm text-gray-700">
                                  {id.trim()}
                                </p>
                              ))
                            : "_"}
                        </div>
                      </div>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={handleClosePopup}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {productInf.filters.series.length > 0 && (
                <>
                  <h2
                    id="return-order-history"
                    className="text-lg font-bold text-dbase1 text-left mb-1 mt-4"
                  >
                    Filters:
                  </h2>
                  <div className="mb-10">
                    {productInf.filters.series.map((serie, serieIndex) => (
                      <div
                        key={serieIndex}
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        {serie.group_type && (
                          <h3
                            id="return-order-history"
                            className="font-bold text-d14 text-left mb-1 mt-4 ml-2"
                          >
                            {serie.group_type}:
                          </h3>
                        )}
                        <div
                          style={{
                            maxHeight: "100%",
                            overflowY: "hidden",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {serie.products.map((item, itemIndex) => (
                            <div
                              className="bg-dbase bg-opacity-15 mb-4 py-2 px-2"
                              key={itemIndex}
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                window.scrollTo({ top: 0, behavior: "smooth" });
                                setSelectedSku(item.sku);
                                productId.current.value = item.sku;
                                setProductInf("");
                                searchProduct(e);
                              }}
                            >
                              <img
                                className="rounded -mt-0.5"
                                src={item.image}
                                alt={item.name}
                                style={{ maxWidth: "150px", height: "auto" }}
                              />
                              <h2
                                className=" text-d18 mt-1"
                                style={{ maxWidth: "150px", height: "auto" }}
                              >
                                {item.sku}
                              </h2>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
    </>
  );
};

export default WarehouseProduct;
