import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import MessageAlert from "../../../components/messageAlert";
import Select from "react-select";
import { CompanyContext } from "../../../contexts/companyCotext";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";

const Jardehv2 = () => {
  var host = window.location.host;
  const history = useNavigate();
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [warehouses, setWarehouses] = useState([]);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [jarderList, setJardehList] = useState(null);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const jardehName = useRef(null);
  const [warehouse_id, setwarehouseID] = useState("");
  const [warehouse_name, setwarehouseName] = useState("");
  const [objectFromCookies, setObjFromCookies] = useState(null);

  async function getJardeh() {
    await axios.get(buildLink("jardeh")).then((res) => {
      setJardehList(res.data.data);
      if ( (host.startsWith("localhost") || host === "pda.ishtari.com")) {
        dispatchCom({ type: "setWarehouseID", payload: warehouse_id });
        dispatchCom({ type: "setWarehouseName", payload: warehouse_name });
      }
    });
  }
  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  useEffect(() => {
    fetchReceivedProducts();
  }, []);


  const fetchReceivedProducts = async () => {
    try {
      const url = buildLink(`fetchReceivedWarehouseProducts`);
      const response = await axios.get(url);
      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };



  async function deleteJardeh(id) {
    const userID = getUserIdFromLocalStorage()
    const obj = {
      jardeh_id: id,
      user_id: userID,
    };
    try {
      await axios
        .delete(buildLink("deleteJardeh"), { data: obj })
        .then((res) => {
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        });
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const handleInputClick = (e) => {
    e.stopPropagation(); // prevent the click event from reaching the handleOutsideClick
  };

  function handleSubmit() {
    if (jardehName.current.value == "") {
      setMessage("Jardeh Name is Required");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } 
    else if (warehouse_id == "" && (host.startsWith("localhost") || host === "pda.ishtari.com")) {
      setMessage("Select Warehouse");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } 
    else {
      history(`/home/jardeh/jardehAddBadge/${jardehName.current.value}/new`);
      if ( (host.startsWith("localhost") || host === "pda.ishtari.com")) {
        dispatchCom({ type: "setWarehouseID", payload: warehouse_id });
        dispatchCom({ type: "setWarehouseName", payload: warehouse_name });
      }
    }
  }

  useEffect(() => {
    getJardeh();
  }, []);

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                <h2 className=" text-xl    my-auto">Jardeh</h2>
              </div>
              <button onClick={() => setShowModal(true)}>
                <FaPlus />
              </button>
            </div>
          </div>
        </div>

        <div className=" flex flex-col gap-3">
          {jarderList &&
            jarderList.map((jardeitem) => {
              return (
                <div className="w-full shadow-md py-3 px-3 flex flex-row justify-between  bg-white ">
                  <div
                      onClick={() => {
                        history(jardeitem.jardeh_id, {
                          state: { name: jardeitem.name },
                        });

                        if (host.startsWith("localhost") || host === "pda.ishtari.com") {
                          dispatchCom({ type: "setWarehouseID", payload: jardeitem.warehouse_id });
                          dispatchCom({ type: "setWarehouseName", payload: jardeitem.warehouse_name });
                        }
                      }}
                      className="h-full w-full flex flex-col text-left"
                    >

                    <h2>{jardeitem.name}</h2>
                    <p className=" text-sm   text-dlabelColor ">
                      {" "}
                      <span className=" text-dgreySeller">User: </span>
                      {jardeitem.username}
                    </p>
                    <p className=" text-sm   text-dlabelColor ">
                      {" "}
                      <span className=" text-dgreySeller">DateStart: </span>
                      {jardeitem.date_start}
                    </p>
                    <p className=" text-sm   text-dlabelColor ">
                      {" "}
                      <span className=" text-dgreySeller">DateEnd: </span>
                      {jardeitem.date_end}
                    </p>
                  </div>
                  <div className=" h-fit my-auto">
                    <button onClick={() => deleteJardeh(jardeitem.jardeh_id)}>
                      <FaTrash className="text-dbase" />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>

      {showModal && (
        <>
          <div
            onClick={closeModal}
            className=" bg-dblack bg-opacity-50 transition-opacity container justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="shadow-md relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div
                onClick={handleInputClick}
                className="transition-transform border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
              >
                {/*header*/}
                <div className=" containerflex items-start justify-between p-5 border-b border-solid border-dgrey rounded-t transform transition-transform">
                  <h3 className="text-3xl text-dblack font-semibold text-center">
                    Jardeh Name
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <input
                    ref={jardehName}
                    type="text"
                    className="border  border-dlabelColor  outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                    placeholder="Enter Jardeh Name"
                  />
                  {( host.startsWith("localhost") || host === "pda.ishtari.com") && (
                  <Select
                  placeholder="Select Warehouse"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "7px",
                      borderColor: state.isFocused ? "grey" : "grey",
                      padding: "8px 5px",
                      height: "48px",
                    }),
                  }}
                  options={warehouses.map((warehouse) => ({
                    value: warehouse.warehouse_id,
                    label: warehouse.warehouse_name,
                  }))}
                  value={warehouses
                    .map((warehouse) => ({
                      value: warehouse.warehouse_id,
                      label: warehouse.warehouse_name,
                    }))
                    .find((option) => option.value === warehouse_id)}
                  onChange={(option) => {
                    setwarehouseID(option.value); 
                    setwarehouseName(option.label); 
                  }}
                className="mt-3"/>
                )}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-dgrey rounded-b">
                  <button
                    className=" bg-dbase text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mx-auto ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default hocStarredPage(Jardehv2);
