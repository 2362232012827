import React, { useRef, useState, useEffect, useContext } from "react";
import { FaArrowLeft, FaSearch, FaPaperPlane, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import MessageAlert from "../../../components/messageAlert";
import Loader from "../../../components/loader";
import axios from "axios";
import buildLink from "../../../urls";
import { PlaySounds } from "../../../functions/playSounds";
import { CompanyContext } from "../../../contexts/companyCotext";
import Select from "react-select";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import SingleProduct from "../../../components/SingleProduct";
import ReactPaginate from "react-paginate";

const WareHouseStudioRestock = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehousefrom, setSelectedWarehousefrom] = useState(null);
  const [selectedWarehouseto, setSelectedWarehouseto] = useState(null);
  const [inputQuantity, setInputQuantity] = useState("");
  const [logEntries, setLogEntries] = useState([]);
  const [address, setAddress] = useState("");
  const [reference, setReference] = useState("");
  const [realaddress, setRealAddress] = useState("");
  const [addresstype,setSelectAddressType]=useState("bin");
  const history = useNavigate();
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [loadingSearch1, setLoadingSearch1] = useState(false);
  const [loadingSearch2, setLoadingSearch2] = useState(false);
  const [loadingSearch3, setLoadingSearch3] = useState(false);
  const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
  const [error, setError] = useState(null);
  const AddressID = useRef(null);
  const WareHouseID = useRef(null);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [ProductInfo, setProductInfo] = useState([]);
  const [Counter, setCounter] = useState(0);
  const [CounterTemp, setCounterTemp] = useState(0);
  const [userID, setUserID] = useState(null);
  const [isQuantityInputDisabled, setIsQuantityInputDisabled] = useState(false);
  const { name, id } = useParams();
  const decodedName = decodeURIComponent(name);
  const [status, setstatus] = useState(0);
  const [status2, setstatus2] = useState("");
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [towarehouse, settowarehouse] = useState("");
  const [fromwarehouse, setfromwarehouse] = useState("");
  const [order_id, setOrderID] = useState("");
  const inputQuantityy = useRef(null);
  const [objectFromCookies, setObjFromCookies] = useState(null);
  const productID = useRef(null);

  const [ReceivedWarehouseProducts, setReceivedWarehouseProducts] = useState(
    []
  );

  useEffect(() => {
    const user_id = localStorage.getItem("userID");
    setUserID(user_id);
  }, []);


  const fetchReceivedProducts = async () => {
    try {
      const url = buildLink(`fetchReceivedWarehouseProducts`);
      const response = await axios.get(url);
      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    const warehouse = warehouses.find(w => w.warehouse_id === towarehouse);
    if (warehouse) {
      setSelectedWarehouse(warehouse.warehouse_name);
    }
  }, [warehouses, towarehouse]);

  useEffect(() => {
    if (objectFromCookies) {
      if (objectFromCookies.selectedWarehousefrom && warehouses) {
        setSelectedWarehousefrom(objectFromCookies.selectedWarehousefrom);
      }
      if (objectFromCookies.selectedWarehouseto && warehouses) {
        setSelectedWarehouseto(objectFromCookies.selectedWarehouseto);
      }
    }
  }, [warehouses, objectFromCookies]);

  useEffect(() => {
    setstatus(8);
    setstatus2(8);
  }, []);

  const clearInputs = () => {
    productID.current.value = "";
    setAddress("");
    setReference("");
    setInputQuantity("");
    AddressID.current.value="";
  };

  useEffect(() => {
    const response = Cookies.get(decodedName);
    if (response) {
      setObjFromCookies(JSON.parse(response));
      // console.log(response);
    }

    fetchReceivedProducts();
  }, []);


  const getTransferOrderByProduct = async (e) => {
    e.preventDefault(); // Prevent the page from refreshing
    setLoadingSearch2(true);
    const item = productID.current.value; // Get the product ID
  
    const url =
      buildLink("getTransferOrderByProduct") +
      `&product=${item}` + `&status=8`;
  
    try {
      const response = await axios.get(url);
  
      if (response.data.success) {
        playSuccessSound();
        setMessage("Success");
        setShowMessageSucc(true);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        settowarehouse(response.data.to_warehouse);
        setfromwarehouse(response.data.from_warehouse);
        setOrderID(response.data.order_id);
        setRealAddress(response.data.products.bin_id || response.data.products.upc);
        setAddress(response.data.products.address);
        setReference(response.data.products.upc);

      } else {

        playErrorSound();
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    } catch (error) {
      playErrorSound();
      setLoadingSearch2(false);
  
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An unknown error occurred');
      }
  
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } finally {
      setLoadingSearch2(false);
    }
  };
  
  const changestatus = async (e) => {
    e.preventDefault();
    let productIdTemp = productID.current.value;
    const product = productID.current.value;
    const address_id = AddressID.current.value;
    const quantity = inputQuantity;
    const address_type=addresstype;
    const to_warehouse = towarehouse;
    const from_warehouse = fromwarehouse;
    const orderID = order_id;
    const user_id = userID;
    if (!product || !address_id || !quantity) {
      // Check if all the required fields are filled
      setMessage(
        "Please fill in all the required fields: Product, Address, Quantity."
      );
      setShowMessageErr(true);
      setTimeout(() => setShowMessageErr(false), 10000);
      return;
    }
    if (inputQuantity<=0) {
      setMessage("Quantity Must Be greater than 0");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }

    if (CounterTemp < 30 && CounterTemp - 1 > 0) {
      setCounterTemp(CounterTemp - 1);
    } else {
      setIsQuantityInputDisabled(true); 
      setLoadingSearch3(true);
      try {
        const obj = {
          status,
          product:product,
          address_id:address_id,
          address_type,
          order_id:orderID,
          user_id,
          to_warehouse: to_warehouse,
          from_warehouse: from_warehouse,
          quantity,
        };

        const url = buildLink("changeProductStatus");
        const response = await axios.post(url, obj);

        if ( response.data.success ) {
          setLoadingSearch3(false);
          setMessage("Product updated successfully");
          playSuccessSound();
          setShowMessageSucc(true);
          setTimeout(() => setShowMessageSucc(false), 10000);
          setRealAddress("");
          setAddress("");
          setReference("");
          productID.current.value="";
          AddressID.current.value="";
          setInputQuantity("");
        }
        else {
          setLoadingSearch3(false);
          setMessage("Error");
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
          playErrorSound();
        }
      } catch (e) {
        setLoadingSearch3(false);
        console.log(e);
        setMessage(e.response?.data?.message);
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 10000);
        clearInputs();
      } finally {
        setIsQuantityInputDisabled(false);
      }
    }
  };

  const handleTrashClick = () => {
    productID.current.value = "";
    productID.current.focus();
  };

  const itemsPerPage = 10; // Number of items to show per page
  const [visibleItemsCount, setVisibleItemsCount] = useState(itemsPerPage); //  Number of items to show

  const currentProducts = ProductInfo?.slice(0, visibleItemsCount); // Get the current products to show

  const handleLoadMore = () => {
    setVisibleItemsCount((prevCount) => prevCount + itemsPerPage); // Load more items
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };


  return (
    <div className="overflow-hidden h-screen"> 
    <div className="mb-5 sticky top-0 flex flex-col bg-white z-50 shadow-md"> 
      <div className="w-full bg-dbase h-16">
        <div className="container px-3 relative flex justify-between w-full text-white h-full">
          <div className="flex gap-7 flex-row justify-start">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className="text-xl my-auto">WareHouse Studio Restock</h2>
          </div>
        </div>
      </div>
    </div>

    <div className="container overflow-auto max-h-[calc(100vh-4rem)] px-4 py-6"> {/* Allow scrolling and reduce max height by header size */}
   
  
      {/* Form Inputs */}
      <div className="container mx-auto">
        <form onSubmit={(e) => getTransferOrderByProduct(e)}>
          <div className="flex flex-col">
            <div className="mb-1">Product ID or SKU</div>
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
              <div className="relative flex flex-row border-dblack rounded-lg text-center w-full gap-3">
                <input
                  ref={productID}
                  type="text"
                  className="border border-dblack rounded-lg block p-2 flex-grow text-start w-full h-12 pr-10"
                  placeholder="Product ID or SKU"
                />
                <FaTrash
                  onClick={() => handleTrashClick()}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                />
              </div>
  
              <button className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12">
                {loadingSearch2 ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </div>
        </form>
        <div className="mt-3">
        <div className="mb-1">To Warehouse</div>
              <input
        type="text"
        readOnly
        placeholder="To Warehouse"
        value={selectedWarehouse} 
        className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
      />

                </div>
        <div className="py-2">
  <div className="mb-1">Address Type</div>
  <Select
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: "7px",
        borderColor: state.isFocused ? "grey" : "grey",
        padding: "8px 5px",
      }),
    }}
    value={{ value: addresstype, label: addresstype.charAt(0).toUpperCase() + addresstype.slice(1) }}
    onChange={(selectedOption) => setSelectAddressType(selectedOption.value)}
    options={[
      { value: 'bin', label: 'Bin' },
      { value: 'reference', label: 'Reference' }
    ]}
    defaultValue={{ value: 'bin', label: 'Bin' }}
    placeholder="Select Warehouse"
    className="w-full"
  />
</div>

<div className="flex flex-col mt-3">
          <div className="flex mb-1">
            <span className="mr-2">Address:</span>
              <span>{address}</span>
                {reference !== "" && (
                <>
                  <span className="ml-1 mr-1">Reference:</span>
                  <span>{reference}</span>
                </>
              )}

            </div>          

          <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
            <input
              ref={AddressID}
              type="text"
              className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
              placeholder="Address ID"
            />
          </div>
        </div>
        {/* Quantity Form */}
        <form onSubmit={changestatus}>
          <div className="flex flex-col">
            <div className="py-3">
              <div className="mb-1">Quantity</div>
              <div className="flex items-center gap-3">
                <input
                  required
                  type="number"
                  min={1}
                  value={inputQuantity}
                  ref={inputQuantityy}
                  onChange={(e) => {
                    const quantity = e.target.value;
                    setInputQuantity(quantity);
                    setCounterTemp(quantity);
                  }}
                  placeholder="Quantity to Transfer"
                  className="border p-2 rounded-md w-full h-12"
                />
              </div>
  
              {inputQuantity > 0 && (
                <div className="mt-2 text-lg font-semibold">
                  Counter: {CounterTemp}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="bg-dbase rounded-md mt-5 text-white px-5 py-3 flex justify-center items-center"
              disabled={loadingSearch3}
            >
              {loadingSearch3 ? <Loader /> : <FaPaperPlane />}
            </button>
          </div>
        </form>
      </div>
  
      {/* Products Grid */}
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 lg:px-10 lg:ml-20 px-4 lg:gap-4 p-2">
          {currentProducts?.map((product, index) => (
            <SingleProduct key={index} item={product} />
          ))}
        </div>
  
        {ProductInfo?.length > visibleItemsCount && (
          <div className="mt-4 flex justify-center">
            <button
              onClick={handleLoadMore}
              className="px-6 py-2 bg-dbase text-white mb-3 rounded-lg hover:bg-blue-600"
            >
              Load More
            </button>
          </div>
        )}
      </div>
  
      {/* Message Alert */}
      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
    </div>
  </div>
  
  );
};

export default WareHouseStudioRestock;
