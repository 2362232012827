import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheck,
  FaEye,
  FaPlus,
  FaPlusCircle,
  FaSearch,
  FaTrash,
} from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import buildLink from "../../../urls";
import Loader from "../../../components/loader";
import { format,subHours } from "date-fns";
import { CompanyContext } from "../../../contexts/companyCotext";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import Cookies from "js-cookie";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import WareHouse from "../../version2pages/warehousePages/warehousepage";
import WarehouseProduct from "../../version2pages/warehousePages/wahrehouseproduct";
const JardehAddBatch = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const barcodeRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const nameBatchRef = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const { name, id } = useParams();
  var host = window.location.host;

  const [selectedTab, setSelectedTab] = useState(
    (host === "pda.flo-lebanon.com" || host === "pda.englishhome.com.lb") ? "UPC" : "Bin"
  );
 
  

  const handleAddProducts = (e) => {
    e.preventDefault();
  
    if (!barcodeRef.current.value) {
      toast.error('Try Again', {
        autoClose: 500, 
      });
      return;
    }
  
    const currentDate = new Date();
    const dateMinusTwoHours = subHours(currentDate, 2);
    let formattedDate;
    if(host === "pda.ishtari.com.gh" ){
     formattedDate = format(dateMinusTwoHours, "yyyy-MM-dd HH:mm:ss");
    }
    else{
      formattedDate = format(currentDate, "yyyy-MM-dd HH:mm:ss");
    }
     

    let obj;

    obj = {
      barcode: barcodeRef.current.value,
      date: formattedDate,
    };

  
    setProducts((prev) => [...prev, obj]);
    barcodeRef.current.value = "";
  };
  

  function deleteItemJarde(i) {
    const updatedProducts = [...products];

    // Remove the product at the specified index
    updatedProducts.splice(i, 1);

    // Update the state with the new array
    setProducts(updatedProducts);
  }

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  if ((host.startsWith("localhost") || host === "pda.ishtari.com")) {
    const warehouse_id = stateCom?.warehouse_id;
    const warehouse_name = stateCom?.warehouse_name;
  
    if (warehouse_id && warehouse_name) {
      localStorage.setItem('warehouse_id', warehouse_id);
      localStorage.setItem('warehouse_name', warehouse_name);
    }
  }
  
  const warehouse_id = localStorage.getItem('warehouse_id');
  const warehouse_name = localStorage.getItem('warehouse_name');
  

  async function addJardeBatch() {
    setLoading(true);
    const userID = getUserIdFromLocalStorage();
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");

    const productsList = products.map((pr) => {
      const scanTime = format(new Date(), "HH:mm:ss");
      return {
        barcode: pr.barcode,
        scan_date: pr.date,
      };
    });

    let url = '';
    if(window.location.host == 'pda.ishtari.com' ||  window.location.host == 'pda.ishtari.com.gh' || window.location.host == 'localhost:3001' ){
      url =  buildLink("addJardehishtari");
    }else{
      url =  buildLink("addJardeh");
    }
 
   let obj;

    if ( (host.startsWith("localhost") || host === "pda.ishtari.com")) {
      obj = {
        jardeh_name: name,
        batch_name: nameBatchRef.current.value,
        date_start: products[0].date,
        user_id: userID,
        warehouse_id:warehouse_id,
        warehouse_name:warehouse_name,
        date_end: formattedDate,
        products: productsList,
        type: selectedTab === "Bin" ? "bin" : "upc",
      };
    
  }
else{
  obj = {
    jardeh_name: name,
    batch_name: nameBatchRef.current.value,
    user_id: userID,
    date_start: products[0].date,
    date_end: formattedDate,
    products: productsList,
    type: selectedTab === "Bin" ? "bin" : "upc",
  };
}

    

    if (id !== "new") {
      obj.jardeh_id = id;
    }

    try {
      const res = await axios.post(url, obj);
     
      playSuccessSound();
      setMessage(res.data.message);
      setShowMessageSucc(true);
      setTimeout(() => {
        setShowMessageSucc(false);
      }, 3000);
      setShowModal(false);
      dispatchCom({ type: "setWarehouseID", payload: "" });
      dispatchCom({ type: "setWarehouseName", payload: "" });
      localStorage.removeItem('warehouse_id');
localStorage.removeItem('warehouse_name');

      history(-1);
    } catch (e) {
      playErrorSound();
      console.log(e);
      setMessage(e.response ? e.response.data.message : "An error occurred");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }



  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    barcodeRef.current.focus();
  }, []);

  

  useEffect(() => {
    if (showModal) {
      nameBatchRef.current.focus();
    }
  }, [selectedTab, showModal]); 

  return (
    <>
      <div className=" h-full bg-white">
        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-dblackk bg-opacity-50 w-full h-full">
            <div className="relative w-full max-w-3xl sm:max-w-full sm:px-4 md:w-[700px]">
              {/* Content */}
              <div
                onClick={handleInputClick}
                className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none"
              >
                {/* Header */}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t mb-4">
                  <h3 className="text-3xl font-semibold text-center text-gray-900">
                    Batch Name
                  </h3>
                  <button
                    className="ml-auto bg-transparent text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                {/* Tabs */}
                <div className="flex justify-around w-full">
                {( host.startsWith("localhost") || host === "pda.ishtari.com"  || host === "pda.ishtari.com.gh") && (
                  <button
                    onClick={() => setSelectedTab("Bin")}
                    className={`py-2 px-4 w-full ${selectedTab === "Bin" ? "font-bold bg-dbase text-white" : ""}`}
                  >
                    Bin
                  </button>
      )}
                  <button
                    onClick={() => setSelectedTab("UPC")}
                    className={`py-2 px-4 w-full ${selectedTab === "UPC" ? "font-bold bg-dbase text-white" : ""}`}
                  >
                    UPC
                  </button>
                </div>



                {/* Body */}
                <div className="relative p-6 flex-auto">
                  <input
                    ref={nameBatchRef}
                    type="text"
                    className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                    placeholder="Enter Batch Name"
                  />
                </div>

                {/* Footer */}
                <div className="flex items-center  p-6 border-t border-solid border-dgrey justify-between rounded-b">
                  <button
                    className="text-dbase border rounded font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-dgreen text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => addJardeBatch()}
                  >
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
           <button onClick={() => { 
                      history(-1);  
                      dispatchCom({ type: "setWarehouseID", payload: "" });
                      dispatchCom({ type: "setWarehouseName", payload: "" });
                      localStorage.removeItem('warehouse_id');
                       localStorage.removeItem('warehouse_name');

                  }}>
                  <FaArrowLeft />
                </button>
                <h2 className=" text-xl    my-auto">Jardeh ({name && name})</h2>
              
              </div>
           {(host.startsWith("localhost") || host === "pda.ishtari.com") && warehouse_name && (
                <h2 className="text-xl my-auto flex">Warehouse: {warehouse_name}</h2>
              )}
            </div>
          </div>
        </div>

        <div className=" flex flex-col gap-3">
          <div className="container ">
            <div className=" flex flex-row justify-between items-center gap-3 ">
              <div className="relative flex-1">
             
                <form onSubmit={(e) => handleAddProducts(e)}>
                  <input
                    ref={barcodeRef}
                    type="text"
                    className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                    placeholder="Barcode"
                  />
                </form>
                <div>
                  <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                </div>
              </div>
              <span>{products.length}</span>

              <button
                onClick={() => setShowModal(true)}
                className=" bg-dbase rounded-md text-white px-4 py-4 "
              >
                {" "}
                <FaCheck size={16}/>
              </button>
            </div>

            <div className=" mt-5">
              <table className="min-w-full divide-y border border-dplaceHolder divide-dgrey  ">
                <thead className="bg-dgrey">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider"
                    >
                      Barcode
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider"
                    >
                      Date Added
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider"
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((product, i) => {
                      return (
                        <tr className="  border-b  border-dplaceHolder">
                          <td className=" py-2 border-r border-dplaceHolder  text-center w-fit mx-auto">
                            {product.barcode}
                          </td>
                          <td className=" py-2 border-r border-dplaceHolder text-center w-fit mx-auto">
                            {product.date}
                          </td>
                          <td className=" py-2 border-r border-dplaceHolder text-center w-fit mx-auto">
                            {
                              <div
                                onClick={() => {
                                  deleteItemJarde(i);
                                }}
                                className=" w-fit mx-auto"
                              >
                                <FaTrash className=" text-dbase" />
                              </div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>



    </>
  );
};

export default JardehAddBatch;
