import { FaArrowLeft, FaCheckDouble, FaPaperPlane } from "react-icons/fa";
import MessageAlert from "../../../components/messageAlert";
import hocStarredPage from "../../../components/hocStarredPage";
import NavigateButton from "../../../components/buttonsNavigate";
import { FcProcess } from "react-icons/fc";
import { BsFillSendPlusFill } from "react-icons/bs";
import { MdCallReceived } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const ChangeProductStatusv2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 z-30 flex flex-col bg-white">
          <div className=" w-full  bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => navigate(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Change Product Status</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="grid grid-cols-2 container gap-x-6 gap-y-4">
            {/* <NavigateButton
                icon={<FcProcess />}
                name={"Processing"}
                color={"bg-[rgb(150,200,6)]"}
                onclick={() => navigate("Processing/1")}
              /> */}
            {!window.location.host.includes("pda.ishtari.com.gh") && 
        
            (
              <NavigateButton
                icon={<FaPaperPlane />}
                name={"Send To Data Entry"}
                color={"bg-[rgb(100,0,250)]"}
                onclick={() => navigate("Send To Data Entry/2")}
              />
            )}

            {!window.location.host.includes("pda.ishtari.com.gh") && 
           (
              <NavigateButton
                icon={<FaCheckDouble />}
                name={"Received To Data Entry"}
                color={"bg-[rgb(40,150,8)]"}
                onclick={() => navigate("Received To Data Entry/3")}
              />
            )}

            <NavigateButton
              icon={<BsFillSendPlusFill />}
              name={
                window.location.host.includes("pda.ishtari.com.gh")
                  ? "Send To Studio"
                  : "Send To Stock"
              }
              color={"bg-[rgb(7,9,230)]"}
              onclick={() => {
                const destination = window.location.host.includes(
                  "pda.ishtari.com.gh"
                )
                  ? "Send To Studio/4"
                  : "Send To Stock/4";
                navigate(destination);
              }}
            />

            {(!window.location.host.includes("pda.ishtari.com.gh") ) && (
              <NavigateButton
                icon={<MdCallReceived />}
                name={"Received To Stock"}
                color={"bg-[rgb(6,65,50)]"}
                onclick={() => navigate("Received To Stock/5")}
              />
            )}

            <NavigateButton
              icon={<GrCompliance />}
              name={"Restock"}
              color={"bg-[rgb(0,0,139)]"}
              onclick={() => navigate("Restock/6")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default hocStarredPage(ChangeProductStatusv2);
