import React, { useRef, useState, useEffect } from "react";
import { FaArrowLeft, FaSearch, FaPaperPlane } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import MessageAlert from "../../../components/messageAlert";
import Loader from "../../../components/loader";
import axios from "axios";
import buildLink from "../../../urls";
import { PlaySounds } from "../../../functions/playSounds";
import Select from "react-select";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const StatusComponent = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
    const [loadingProductSearch, setLoadingProductSearch] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehousefrom, setSelectedWarehousefrom] = useState(null);
  const [selectedWarehouseto, setSelectedWarehouseto] = useState(null);
  const [inputQuantity, setInputQuantity] = useState("");
  const [logEntries, setLogEntries] = useState([]);

  const history = useNavigate();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
  
  const [error, setError] = useState(null);
  const OrderID = useRef(null);
  const AddressID = useRef(null);
  const ProductID = useRef(null);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [ProductInfo, setProductInfo] = useState([]);
  const [Counter, setCounter] = useState(0);
  const [CounterTemp, setCounterTemp] = useState(0);
  const [userID, setUserID] = useState(null);
  const [isQuantityInputDisabled, setIsQuantityInputDisabled] = useState(false);
  const { name, id } = useParams();
  const decodedName = decodeURIComponent(name);
  const [status, setstatus] = useState(0);
  const [status2, setstatus2] = useState("");
  const [fromwarehouse, setfromwarehouse] = useState("");
  const [towarehouse, settowarehouse] = useState("");
  const inputQuantityy = useRef(null);
  const [objectFromCookies, setObjFromCookies] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  useEffect(() => {
    const user_id = localStorage.getItem("userID");
    setUserID(user_id);
  }, []);

  const handleSetDataInCookies = (key, value) => {
    const dataFromCookies = Cookies.get(decodedName);
    let data = {};
    if (dataFromCookies) {
      data = JSON.parse(dataFromCookies);
    }
    data[key] = value;
    Cookies.set(decodedName, JSON.stringify(data), { expires: 1 });
  };

  useEffect(() => {
    if (fromwarehouse === "14") {
      setInputQuantity(1);
      setCounterTemp(1);
    }
  }, [fromwarehouse]);

  useEffect(() => {
    if (towarehouse === "14") {
      setInputQuantity(1);
      
      setCounterTemp(1);
    }
  }, [towarehouse]);

  const fetchWarehouses = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);
      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    if (objectFromCookies) {
      if (objectFromCookies.selectedWarehousefrom && warehouses) {
        setSelectedWarehousefrom(objectFromCookies.selectedWarehousefrom);
      }
      if (objectFromCookies.selectedWarehouseto && warehouses) {
        setSelectedWarehouseto(objectFromCookies.selectedWarehouseto);
      }
    }
  }, [warehouses, objectFromCookies]);

  const submitchangedqty = (e) => {
    e.preventDefault();

    const enteredQuantity = parseInt(inputQuantity, 10);

    if (!isNaN(enteredQuantity) && enteredQuantity > 0) {
      setCounter(enteredQuantity);
      setCounterTemp(enteredQuantity);
      setIsQuantityInputDisabled(true);
    } else {
      console.error("Invalid quantity entered.");
    }
  };

  useEffect(() => {
  if (
      window.location.pathname ===
      "/home/v2home/warehousepage/ChangeStatusWarehouse/Send%20To%20Warehouse"
    ) {
      setstatus(2);
      setstatus2(2);
    } else if (
      window.location.pathname ===
      "/home/v2home/warehousepage/ChangeStatusWarehouse/Received%20From%20Warehouse"
    ) {
      setstatus(3);
      setstatus2(3);
    }
  }, []);

  const changestatus = async (e) => {
    e.preventDefault();
    let productIdTemp = ProductID.current.value;
    // console.log("!@312312312312#", CounterTemp);
    if (CounterTemp < 30 && CounterTemp - 1 > 0) {
      setCounterTemp(CounterTemp - 1);
      ProductID.current.value = "";
      ProductID.current.focus();
    } else {
      const order_id = OrderID.current.value;
      const from_warehouse = selectedWarehousefrom?.value;
      const to_warehouse = selectedWarehouseto?.value;
      const user_id = userID;
      const product = ProductID.current.value;

      setIsQuantityInputDisabled(true);
      
      if (inputQuantity<=0) {
        setMessage("Quantity Must Be greater than 0");
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        return;
      }
      setLoadingSearch(true);
      try {
        const obj = {
          order_id,
          status,
          user_id,
          product,
          quantity: inputQuantity,
        };

        if (
          window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Send%20To%20Warehouse"
        ) {
          obj.from_warehouse = fromwarehouse;
          if (fromwarehouse !== "14") {
            obj.to_warehouse = towarehouse;
          }
        }
        
        if (
          window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Received%20From%20Warehouse"
        ) {
          obj.from_warehouse = fromwarehouse;
          if (fromwarehouse !== "14") {
            obj.to_warehouse = towarehouse;
          }
        }
        
        const url = buildLink("changeProductStatus");
        const response = await axios.post(url, obj);

        if (
          response.data &&
          response.data.data &&
          response.data.data.products
        ) {
          setLoadingSearch(false);
          setMessage("Product updated successfully");
          playSuccessSound();
          setShowMessageSucc(true);
          setProductInfo((prevInfo) => prevInfo.filter((prod) => prod.product_id !== productIdTemp));
          setTimeout(() => setShowMessageSucc(false), 10000);

          clearInputs();

          console.log(response.data.data.products);

          const productIndex = response.data.data.products.findIndex(
            (product) => product.product_id === productIdTemp
          );

          if (productIndex !== -1) {
            const product = response.data.data.products[productIndex];

            const newLogEntry = {
              productId: productIdTemp,
              quantity: inputQuantity,
              sku: product.sku,
            };

            setLogEntries((prevEntries) => [newLogEntry, ...prevEntries]);
          }
        } else {
          setLoadingSearch(false);
          setMessage(response.data.message);
          setShowMessageErr(true);
          setTimeout(() => setShowMessageErr(false), 10000);
          clearInputs();
        }
      } catch (e) {
        setLoadingSearch(false);
        console.log(e);
        setMessage(e.response.data.message);
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 10000);
        clearInputs();
      } finally {
        setIsQuantityInputDisabled(false);
      }
    }
  };

  const clearInputs = () => {
    ProductID.current.value = "";
  };

  useEffect(() => {
    const response = Cookies.get(decodedName);
    if (response) {
      setObjFromCookies(JSON.parse(response));
      // console.log(response);
    }

    fetchWarehouses();
  }, []);

  const getOrders = async (e) => {
    e.preventDefault();
    setLoadingProductSearch(true);
    setError(null);
    try {
      const url = `${buildLink(`getTransferOrderById`)}&order_id=${
        OrderID.current.value
      }&status=${status}`;

      const response = await axios.get(url);
      if (response.data.success) {
        setMessage("Order with id: " + OrderID.current.value + " Was Found");
        setShowMessageSucc(true);
        playSuccessSound();
        setfromwarehouse(response.data.from_warehouse);
        settowarehouse(response.data.to_warehouse);
        setTimeout(() => setShowMessageSucc(false), 10000);
        setProductInfo(response.data.products);
        setfromwarehouse(response.data.from_warehouse);

        settowarehouse(response.data.to_warehouse);
        ProductID.current.focus();
      } else {
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 10000);
        playErrorSound();
        OrderID.current.value = "";
      }
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => setShowMessageErr(false), 10000);
      playErrorSound();
      OrderID.current.value = "";
    } finally {
      setLoadingProductSearch(false);
    }
  };

  return (
    <div className="overflow-auto"> {/* Set height for scrolling */}
  {/* Header */}
  <div className="flex flex-col bg-white">
    <div className="w-full bg-dbase h-16">
      <div className="container px-3 relative flex justify-between w-full text-white h-full">
        <div className="flex gap-7 flex-row justify-start">
          <button onClick={() => history(-1)}>
            <FaArrowLeft />
          </button>
          <h2 className="text-xl my-auto">{decodedName}</h2>
        </div>
      </div>
    </div>
  </div>

  <div className="mx-auto p-5">
    <form onSubmit={getOrders}>
      <div className="flex flex-col">
        <div className="mb-1">Order ID</div>
        <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
          <input
            ref={OrderID}
            type="text"
            className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
            placeholder="Order ID"
          />
          <button className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12">
            {loadingProductSearch ? <Loader /> : <FaSearch />}
          </button>
        </div>
      </div>
    </form>

    {/* From Warehouse */}
    <div className="py-3">
      <div className="mb-1">From Warehouse</div>
      {loadingWarehouses ? (
        <div className="text-center text-dbase">
          <Loader />
        </div>
      ) : error ? (
        <p className="text-dbase">{error}</p>
      ) : (
        <Select
          placeholder="Source Warehouse"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: "7px",
              borderColor: state.isFocused ? "grey" : "grey",
              padding: "8px 5px",
              height: "48px",
            }),
          }}
          options={warehouses.map((warehouse) => ({
            value: warehouse.warehouse_id,
            label: warehouse.warehouse_name,
          }))}
          value={warehouses
            .map((warehouse) => ({
              value: warehouse.warehouse_id,
              label: warehouse.warehouse_name,
            }))
            .find((option) => option.value === fromwarehouse)}
          onChange={(option) => {
            setfromwarehouse(option.value);
          }}
        />
      )}
    </div>

    {/* To Warehouse */}
    {fromwarehouse !== "14" && (
      <div className="py-3">
        <div className="mb-1">To Warehouse</div>
        {loadingWarehouses ? (
          <div className="text-center text-dbase">
            <Loader />
          </div>
        ) : error ? (
          <p className="text-dbase">{error}</p>
        ) : (
          <Select
            placeholder="Source Warehouse"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "7px",
                borderColor: state.isFocused ? "grey" : "grey",
                padding: "8px 5px",
                height: "48px",
              }),
            }}
            options={warehouses.map((warehouse) => ({
              value: warehouse.warehouse_id,
              label: warehouse.warehouse_name,
            }))}
            value={warehouses
              .map((warehouse) => ({
                value: warehouse.warehouse_id,
                label: warehouse.warehouse_name,
              }))
              .find((option) => option.value === towarehouse)}
            onChange={(option) => {
              settowarehouse(option.value);
            }}
          />
        )}
      </div>
    )}

    {/* Conditional Rendering for Address */}
    {window.location.pathname == "/home/v2home/warehousepage/ChangeStatusWarehouse/Warehouse%20Restock" && (
      <div className="flex flex-col mt-3">
        <div className="mb-1">Address</div>
        <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
          <input
            ref={AddressID}
            type="text"
            className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
            placeholder="Address ID"
          />
        </div>
      </div>
    )}

    <form onSubmit={changestatus}>
      <div className="flex flex-col">
        <div className="py-3">
          <div className="mb-1">Quantity</div>
          <div className="flex items-center gap-3">
            <input
              required
              type="number"
              min={1}
              value={inputQuantity}
              ref={inputQuantityy}
              onChange={(e) => {
                const quantity = e.target.value;
                setInputQuantity(quantity);
                setCounterTemp(quantity);
              }}
              placeholder="Quantity to Transfer"
              className="border p-2 rounded-md w-full h-12"
            />
          </div>

          {inputQuantity > 0 && (
            <div className="mt-2 text-lg font-semibold">
              Counter: {CounterTemp}
            </div>
          )}
        </div>

        <div className="mb-1 mt-2">Product ID</div>
        <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
          <input
            required
            ref={ProductID}
            type="text"
            className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
            placeholder="Product ID"
          />
        </div>

        <button
          type="submit"
          className={`bg-dbase rounded-md mt-5 text-white px-5 py-3 flex justify-center items-center ${
              loadingSearch ? "opacity-50 cursor-not-allowed" : ""
            } `}
          disabled={loadingSearch}
        >
          {loadingSearch ? <Loader /> : <FaPaperPlane />}
        </button>
      </div>
    </form>

  </div>

  {/* Table Section */}
  <div className="mt-5 px-6 pb-2">
    <h3 className="text-lg font-semibold mb-3">Order Products</h3>
    <table className="w-full table-auto border-collapse border">
      <thead>
        <tr className="border-b">
          <th className="px-4 py-2 border-r">Product ID</th>
          <th className="px-4 py-2 border-r">Quantity</th>
          <th className="px-4 py-2">Sku</th>
        </tr>
      </thead>
      <tbody>
        {ProductInfo &&
          ProductInfo.map((entry, index) => (
            <tr key={index} className="border-b">
              <td className="px-4 py-2 text-center border-r">
                {entry.product_id}
              </td>
              <td className="px-4 py-2 text-center border-r">
                {entry.quantity}
              </td>
              <td className="px-4 py-2 text-center border-r">
                {entry.sku}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>

  {/* Message Alert */}
  <MessageAlert
    message={message}
    type={showMessgErr ? "err" : "succ"}
    showMessg={showMessgErr || (showMessgSucc && true)}
  />
</div>

  );
};

export default StatusComponent;
